import { useRecoilValue } from "recoil";
import { IBaseResponse } from "../../commons/types/base";
import { IComandasPorFase, IComandasPorStatus, IEtapasJaRealizadas } from "../../commons/types/dashboard";
import { useAxiosWrapper } from "../../services/axiosWrapper";
import { authAtom } from "../usuarios/AuthState";
import { useUsuarioActions } from "../usuarios/usuario.actions";

function useGraficosActions() {
  const userActions = useUsuarioActions();
  const axiosWrapper = useAxiosWrapper();
  const authState = useRecoilValue(authAtom);
  const baseUrl = `${process.env.REACT_APP_API_URL}/v1/empresas/${authState?.empresaId}/dashboard`;
  if (!authState || authState!.empresaId === undefined) {
    userActions.logout();
  }

  return {
    getComandasPorStatus,
    getComandasPorFase,
    getEtapasJaRealizadas
  }

  async function getComandasPorStatus(): Promise<IComandasPorStatus[]> {
    const resp: IComandasPorStatus[] = await axiosWrapper.get(
      `${baseUrl}/comandasPorStatus`
    );
    return resp;
  }

  async function getComandasPorFase(): Promise<IComandasPorFase[]> {
    const resp: IComandasPorFase[] = await axiosWrapper.get(`${baseUrl}/comandasPorFase`);
    return resp;
  }

  async function getEtapasJaRealizadas(): Promise<IEtapasJaRealizadas[]> {
    const resp: IEtapasJaRealizadas[] = await axiosWrapper.get(`${baseUrl}/etapasJaRealizadas`);
    return resp;
  }
}

export { useGraficosActions };

