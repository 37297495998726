import { withRouter } from 'react-router-dom';
import Logo from '../../assets/Logo-Stato-Intoo_1.png';
import List from '@mui/material/List';
import ListItemText from '@mui/material/ListItemText';

import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';

import { useEffect, useState } from 'react';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { history } from '../../helpers/history';
import { Drawer, DrawerHeader, BoxMenu } from './MenuStyle';
import React from 'react';
import Icon from '@mui/material/Icon';
import { styled } from 'styled-components';
import useTheme from '@mui/material/styles/useTheme';
import Header from './header';
import { useUsuarioActions } from '../../states/usuarios/usuario.actions';
import { useRecoilValue } from 'recoil';
import { authAtom } from '../../states/usuarios/AuthState';
import { toast } from 'react-toastify';

interface IModulo {
  privilegio: string;
  nome: string;
  icon: string;
  link: string;
}

export const menuItens: IModulo[] = [
  {
    privilegio: '',
    icon: 'dashboardRounded',
    nome: 'Dashboard',
    link: '/',
  },
  {
    privilegio: '',
    icon: 'groupsRounded',
    nome: 'Programas',
    link: '/assessorados',
  },
  {
    privilegio: '',
    icon: 'exit_to_appRounded',
    nome: 'Sair',
    link: '/exit',
  },
];

const MenuBar: React.FC = (props: any) => {
  const { children } = props;
  const path = window.location.pathname;
  const usuarioActions = useUsuarioActions();
  const auth = useRecoilValue(authAtom);
  const [open, setOpen] = useState(false);
  const theme = useTheme();

  useEffect(() => {
    if (!auth || !auth.token) {
      localStorage.removeItem('usuario');
      history.push('/login');
    }

    const menuOpened = localStorage.getItem('menuOpened');
    if (menuOpened !== null) setOpen(menuOpened === 'true');
    else setOpen(true);
  }, [auth]);

  const handleClickDrawer = () => {
    localStorage.setItem('menuOpened', (!open).toString());
    setOpen(!open);
  };

  const setHistory = (link: string) => {
    if (link === '/exit') {
      logout();
      return;
    }
    history.push(link);
  };

  const logout = () => {
    usuarioActions.logout();
    toast.info('Logout realizado com sucesso!');
  };

  return (
    <Grid container>
      <Header
        open={open}
        handleClickDrawer={handleClickDrawer}
        titulo={
          menuItens.find(
            (menuItem) =>
              path === menuItem.link ||
              (menuItem.link.includes(path.split('/')[1]) &&
                path.split('/')?.length > 2)
          )?.nome ?? ''
        }
      />

      <Drawer variant="permanent" open={open}>
        <DivStato>
          <DrawerHeader>
            <div />
            {open && (
              <LogoGridItem
                item
                xs={12}
                md={6}
                style={{ height: '130px', marginTop: '40px' }}
              >
                {' '}
                <img src={Logo} alt="logo" width={'190px'} />
              </LogoGridItem>
            )}

            <IconButton
              sx={{ color: 'grey' }}
              onClick={handleClickDrawer}
              hidden={true}
            >
              {theme.direction === 'rtl' ? (
                <ChevronRightIcon />
              ) : (
                <ChevronLeftIcon />
              )}
            </IconButton>
          </DrawerHeader>

          <List sx={{ marginTop: '30px' }}>
            {menuItens.map((menuItem, index) => (
              <ListItemStato
                key={menuItem.nome}
                disablePadding
                ativo={
                  path === menuItem.link ||
                    (menuItem.link.includes(path.split('/')[1]) &&
                      path.split('/')?.length > 2)
                    ? 'true'
                    : 'false'
                }
              >
                <ListItemButton
                  onClick={() => setHistory(menuItem.link as string)}
                  sx={{
                    justifyContent: open ? 'initial' : 'center',
                  }}
                >
                  <ListItemIconStato
                    open={open}
                    ativo={
                      path === menuItem.link ||
                        (menuItem.link.includes(path.split('/')[1]) &&
                          path.split('/')?.length > 2)
                        ? 'true'
                        : 'false'
                    }
                  >
                    <Icon style={{ fontSize: 40 }}>{menuItem.icon}</Icon>
                  </ListItemIconStato>
                  <MenuItemStato
                    primary={menuItem.nome}
                    open={open}
                    ativo={
                      path === menuItem.link ||
                        (menuItem.link.includes(path.split('/')[1]) &&
                          path.split('/')?.length > 2)
                        ? 'true'
                        : 'false'
                    }
                  />
                </ListItemButton>
              </ListItemStato>
            ))}
          </List>
        </DivStato>
      </Drawer>

      <BoxMenu open={open} component="main">
        <GridConteudoPrincipal container>{children}</GridConteudoPrincipal>
      </BoxMenu>
    </Grid>
  );
};

const GridConteudoPrincipal = styled(Grid)`
  overflow-x: hidden;
  width: 100%;
  min-height: 80%;
  height: 100%;
`;

interface IListMenu {
  ativo?: boolean | string;
  open?: boolean;
}

const ListItemStato = styled(ListItem) <IListMenu>`
  display: block;
  max-width: 281px;
  border-radius: 10px;
  margin: 0 auto;
  margin-top: 20px;
  width: 100%;
  background-color: ${(p) =>
    p.ativo === 'true' ? '#118BD7 !important' : 'unset'};
`;

const ListItemIconStato = styled(ListItemIcon) <IListMenu>`
  min-width: 0;
  margin-right: ${(p) => (p.open ? 2 : 'auto')};
  justify-content: center;
  color: ${(p) =>
    p.ativo === 'true' ? '#efefef !important' : '#bbbbbb !important'};
`;

const MenuItemStato = styled(ListItemText) <IListMenu>`
  opacity: ${(p) => (p.open ? 1 : 0)};

  > span {
    font-size: 20px !important;
    font-weight: 500 !important;
    color: ${(p) =>
    p.ativo === 'true' ? '#efefef !important' : '#a7a7a7 !important'};
  }
`;

export const GridItem = styled(Grid)`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  align-content: center;
  background-color: #ffffff;
  @media (min-width: 900px) {
    background-color: ${(props: any) => props.color};
  }
`;

export const LogoGridItem = styled(GridItem)`
  background: transparent;
`;

const DivStato = styled.div`
  min-height: 100vh;
  overflow-y: auto;
  overflow-x: hidden;
  background: #f3f3f3;
`;

export default withRouter(MenuBar);
