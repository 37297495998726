import React from 'react';
import { Bar } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from 'chart.js';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const options = {
  indexAxis: 'y' as any,
  maintainAspectRatio: false,
  plugins: {
    legend: {
      display: true,
      position: 'top' as any
    },
    title: {
      display: true,
      text: 'Gráfico',
      font: {
        size: 16
      }
    },
  },
  responsive: true,
  scales: {
    x: {
      stacked: true,
      grid: {
        display: false
      }
    },
    y: {
      stacked: true,
      grid: {
        display: false
      }
    }
  }
};

const BarChartComponent: React.FC<any> = ({ data, title }) => {
  const getRandomColor = () => {
    let r = Math.floor(Math.random() * 256); // Vermelho: 0-255
    let g = Math.floor(Math.random() * 256); // Verde: 0-255
    let b = Math.floor(Math.random() * 256); // Azul: 0-255
    return `rgba(${r}, ${g}, ${b}, 0.5)`; // Alfa fixado em 0.5 para transparência
  }

  const chartData = {
    labels: data.map((d: any) => d.label),
    datasets: data.map((d: any) => {
      return {
        label: d.label,
        data: data.map((d2: any) => d.label === d2.label ? d2.value : 0),
        backgroundColor: getRandomColor(),
      }
    })
  };

  return <div style={{ height: '200px', width: '100%' }}>
    <Bar
      options={{
        ...options,
        plugins: {
          ...options.plugins,
          title: {
            ...options.plugins.title,
            text: title
          }
        }
      }}
      data={chartData}
    />
  </div>;
};

export default BarChartComponent;

