import { FormControl, Grid, Icon } from '@mui/material';
import styled from 'styled-components';

export const GridContainer = styled(Grid)`
  margin: 0px !important;
  height: 100% !important;
  width: 100%;
`;

export const GridItem = styled(Grid)`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  align-content: center;
  background-color: white;
  @media (min-width: 900px) {
    background-color: ${(props: any) => props.color};
  }
`;

export const FormControlStato = styled(FormControl)`
  background-color: white;
  border-radius: 4px;
`;
