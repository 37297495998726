export const calcularTempoProjeto = (dataInicio: Date, dataFim: Date) => {
  let anos = dataFim.getFullYear() - dataInicio.getFullYear();
  let meses = dataFim.getMonth() - dataInicio.getMonth();
  if (meses < 0) {
    anos--;
    meses += 12;
  }

  let anoDescricao = '';
  if (anos === 1) anoDescricao += '1 ano';
  else if (anos > 1) anoDescricao += `${anos} anos`;

  let mesDescricao = '';
  if (meses === 1) mesDescricao += '1 mês';
  else if (meses > 1) mesDescricao += `${meses} meses`;

  if (Boolean(anoDescricao && mesDescricao))
    return `${anoDescricao} e ${mesDescricao}`;
  else if (Boolean(anoDescricao))
    return anoDescricao;
  else if (Boolean(mesDescricao))
    return mesDescricao;
  return '';
}
