import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { IContatoDetalhes, IContatoResponse } from "../../commons/types/empresa/contatos";
import { history } from "../../helpers/history";
import { useContatosActions } from "../../states/contatos/contatos.actions";
import { Tabs, Tab, Box, Typography, TextField, Grid } from '@mui/material';
import { IComanda } from "../../commons/types/crm";
import styled from "styled-components";
import DetalhesContato from "./Components/Contato";
import ComandaDetalhes from "./Components/Comanda";

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

export interface IParams {
  id: string;
}

const Detalhes: React.FC = () => {
  const { id } = useParams<IParams>();
  const actions = useContatosActions();
  const [contatoDetalhes, setContatoDetalhes] = useState<IContatoDetalhes>();
  const [contato, setContato] = useState<IContatoResponse>();
  const [comanda, setComanda] = useState<IComanda>();
  const [value, setValue] = useState(0);

  useEffect(() => {
    if (!id) history.goBack();

    actions.getContatoDetalhes(id).then((value) => {
      setContatoDetalhes(value);
      setContato(value.contato);
      setComanda(value.comanda);
    });
  }, [id]);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  return (
    <BoxContato sx={{ width: '100%' }}>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
          <Tab label="Detalhes" id="simple-tab-0" aria-controls="simple-tabpanel-0" />
          <Tab label="Programa" id="simple-tab-1" aria-controls="simple-tabpanel-1" />
        </Tabs>
      </Box>

      <TabPanel value={value} index={0}>
        {contato && <DetalhesContato contato={contato} />}
      </TabPanel>
      <TabPanel value={value} index={1}>
        {comanda && <ComandaDetalhes comanda={comanda} />}
      </TabPanel>
    </BoxContato>
  );
}

const BoxContato = styled(Box)`
  width: 100%;
  margin: 20px;
  border-radius: 16px !important;
  background: #f5f5f5;
  border: 1px solid #aaa;
`;

export default Detalhes;
