import { Box, Checkbox, FormControl, FormControlLabel, Grid, TextField, Typography } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import moment from "moment";
import { IComanda, IEtapa } from "../../../../commons/types/crm";
import { calcularTempoProjeto } from "../../../../commons/utils/tempoProjeto";
import MultiSelectNome from "../../../../components/Generics/MultiSelectNome";
import DetalhesContato from "../Contato"

interface IComandaDetalhes {
  comanda: IComanda;
}

const ComandaDetalhes: React.FC<IComandaDetalhes> = ({ comanda }) => {
  return (
    <>
      <Typography variant="h6" style={{ marginBottom: '20px' }}>Informações do Programa</Typography>
      <Box sx={{ width: '100%', p: 2 }}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6} md={4}>
            <TextField
              label="Status"
              value={comanda.status?.nome ?? ''}
              variant="outlined"
              fullWidth
              disabled
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <TextField
              label="BU"
              value={comanda.bUs ?? ''}
              variant="outlined"
              fullWidth
              disabled
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <TextField
              label="Modelo Comercial"
              value={comanda.modeloComercial ?? ''}
              variant="outlined"
              fullWidth
              disabled
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <TextField
              label="Tempo de Projeto"
              value={calcularTempoProjeto(new Date(comanda.dataInicio!), new Date(comanda.dataFim!))}
              variant="outlined"
              fullWidth
              disabled
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <TextField
              label="Data de Início"
              value={comanda.dataInicio ? new Date(comanda.dataInicio).toLocaleDateString() : ''}
              variant="outlined"
              fullWidth
              disabled
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <TextField
              label="Data de Fim"
              value={comanda.dataFim ? new Date(comanda.dataFim).toLocaleDateString() : ''}
              variant="outlined"
              fullWidth
              disabled
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <TextField
              label="Data de Extensão"
              value={comanda.dataExtensao ? comanda.dataExtensao.toLocaleDateString() : ''}
              variant="outlined"
              fullWidth
              disabled
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              label="Plano"
              value={comanda.plano ?? ''}
              variant="outlined"
              fullWidth
              disabled
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              label="Modelo de Trabalho"
              value={comanda.modeloTrabalho ?? ''}
              variant="outlined"
              fullWidth
              disabled
            />
          </Grid>
          <Grid item sm={12}>
            {comanda?.etapas && (
              <FormControl required fullWidth variant='outlined'>
                <MultiSelectNome
                  opcoes={comanda?.etapas as IEtapa[]}
                  setValor={() => {
                    return;
                  }}
                  valor={comanda?.etapas.map((e) => e.id) || []}
                  label='Etapas/Reuniões'
                  disabled
                />
              </FormControl>
            )}
          </Grid>

          <Grid item xs={12} sm={6}>
            <FormControlLabel
              control={<Checkbox checked={comanda.projetoConfidencial} disabled />}
              label="Projeto Confidencial"
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormControlLabel
              control={<Checkbox checked={comanda.planejamento} disabled />}
              label="Planejamento"
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormControlLabel
              control={<Checkbox checked={comanda.relacionamentoMercado} disabled />}
              label="Relacionamento com o Mercado"
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormControlLabel
              control={<Checkbox checked={comanda.coachingIntegracao} disabled />}
              label="Coaching de Integração"
            />
          </Grid>
        </Grid>
      </Box>
    </>
  );
}

export default ComandaDetalhes;

