import { Box, Grid, TextField, Typography } from "@mui/material";
import { IContatoResponse } from "../../../../commons/types/empresa/contatos";

interface IDetalhesContato {
  contato: IContatoResponse;
}

const DetalhesContato: React.FC<IDetalhesContato> = ({ contato }) => {
  return <>
    <Typography variant="h6" style={{ marginBottom: '20px' }}>Informações básicas</Typography>
    <Box sx={{ width: '100%', p: 2 }}>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <TextField
            label="Nome Completo"
            value={contato.nome}
            variant="outlined"
            fullWidth
            disabled
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            label="Email"
            value={contato.email}
            variant="outlined"
            fullWidth
            disabled
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            label="Primeiro Nome"
            value={contato.firstName}
            variant="outlined"
            fullWidth
            disabled
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            label="Último Nome"
            value={contato.lastName}
            variant="outlined"
            fullWidth
            disabled
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            label="Última Remuneração"
            value={contato.ultimaRemuneracao}
            variant="outlined"
            fullWidth
            disabled
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            label="CPF"
            value={contato.cpf}
            variant="outlined"
            fullWidth
            disabled
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            label="Data de Nascimento"
            value={contato.dataNascimento?.toLocaleDateString()}
            variant="outlined"
            fullWidth
            disabled
          />
        </Grid>
      </Grid>
    </Box>
  </>;
}

export default DetalhesContato;

