import Toolbar from '@mui/material/Toolbar';
import MenuIcon from '@mui/icons-material/Menu';

import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';

import { AppBar } from './MenuStyle';
import React from 'react';
import { Avatar, Grid, InputAdornment, TextField } from '@mui/material';
import { Search } from '@mui/icons-material';
import styled from 'styled-components';

interface IHeader {
  open: boolean;
  handleClickDrawer: any;
  titulo: string;
}

const Header: React.FC<IHeader> = ({ open, handleClickDrawer, titulo }) => {
  const handleProfileClick = () => {
  };

  return (
    <AppBar position='fixed' open={open}>
      <ToolbarStato>
        <IconButton
          aria-label='open drawer'
          onClick={handleClickDrawer}
          edge='start'
          sx={{
            marginRight: 5,
            ...(open && { display: 'none' }),
          }}
        >
          <MenuIcon />
        </IconButton>
        <Grid container>
          <Grid item xs={11}>
            <Typography
              style={{ color: '#585858' }}
              variant='h1'
              noWrap
              component='div'
            >
              {titulo}
            </Typography>
          </Grid>

          <Grid item xs={1}>
            <IconButton onClick={handleProfileClick}>
              <Avatar alt='Foto do Perfil' src={'https://miro.medium.com/v2/resize:fit:1400/1*g09N-jl7JtVjVZGcd-vL2g.jpeg'} />
            </IconButton>
          </Grid>
        </Grid>
      </ToolbarStato>
    </AppBar>
  );
};

const ToolbarStato = styled(Toolbar)`
  height: 100%;
`;

export default Header;
