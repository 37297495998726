import { Grid, Typography } from "@mui/material";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import { on } from "events";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { useRecoilValue } from "recoil";
import { styled } from "styled-components";
import { ContainerPages } from "../../commons/styleds/grids";
import { GridContainer } from "../../commons/styleds/styleds";
import { IComandasPorFase, IComandasPorStatus, IEtapasJaRealizadas } from "../../commons/types/dashboard";
import { useGraficosActions } from "../../states/graficos/graficos.actions";
import { authAtom } from "../../states/usuarios/AuthState";
import { useUsuarioActions } from "../../states/usuarios/usuario.actions";
import BarChartComponent from "./Components/BarChart";
import PieChartComponent from "./Components/PieChart";

const Dashboard: React.FC = () => {
  const userActions = useUsuarioActions();
  const user = useRecoilValue(authAtom);
  const actions = useGraficosActions();
  const [contatos, setContatos] = useState([]);
  const [comandasPorFase, setComandasPorFase] = useState<IComandasPorFase[]>([]);
  const [loadingComandasPorFase, setLoadingComandasPorFase] = useState(true);
  const [comandasPorStatus, setComandasPorStatus] = useState<IComandasPorStatus[]>([]);
  const [loadingComandasPorStatus, setLoadingComandasPorStatus] = useState(true);
  const [etapasJaRealizadas, setEtapasJaRealizadas] = useState<IEtapasJaRealizadas[]>([]);
  const [loadingEtapasJaRealizadas, setLoadingEtapasJaRealizadas] = useState(true);

  const getComandasPorFase = () => {
    actions.getComandasPorFase()
      .then((data) => setComandasPorFase(data ?? []))
      .catch((err) => toast.error('Falha ao buscar comandas por fase'))
      .finally(() => setLoadingComandasPorFase(false));
  }

  const getComandasPorStatus = () => {
    actions.getComandasPorStatus()
      .then((data) => setComandasPorStatus(data ?? []))
      .catch((err) => toast.error('Falha ao buscar comandas por status'))
      .finally(() => setLoadingComandasPorStatus(false));
  }

  const getEtapasJaRealizadas = () => {
    actions.getEtapasJaRealizadas()
      .then((data) => setEtapasJaRealizadas(data ?? []))
      .catch((err) => toast.error('Falha ao buscar etapas ja realizadas'))
      .finally(() => setLoadingEtapasJaRealizadas(false));
  }

  useEffect(() => {
    if (!user || !user.empresaId) {
      toast.warn('Faça login para ter acesso ao sistema');
      userActions.logout();
      return;
    }
    getComandasPorFase();
    getComandasPorStatus();
    getEtapasJaRealizadas();
  }, [])

  const getPorcentagemStatus = (status: string) => {
    const total = comandasPorStatus.reduce((acc, item) => acc + item.quantidade, 0);
    const quantidade = comandasPorStatus.find((item) => item.status == status)?.quantidade;
    const porcentagem = (quantidade ?? 0) / total * 100;
    return `${porcentagem.toFixed(0)}%`;
  }

  const columns: GridColDef[] = [
    {
      field: 'nome',
      headerName: 'Assessorado',
      width: 170,
    },
    {
      field: 'etapa',
      headerName: 'Etapa',
      width: 170,
    },
    {
      field: 'consultor',
      headerName: 'Consultor',
      width: 170,
    },
    {
      field: 'dataRealizado',
      headerName: 'Data Realizado',
      width: 170,
    },
    {
      field: 'nome',
      headerName: 'Assessorado',
      width: 170,
    },
  ]

  return (
    <ContainerPages container>
      <GridItem
        container
        xs={12}
        sx={{
          height: '150px',
          marginBottom: '10px',
          padding: '25px 15px',
          columnGap: '10px'
        }}
        direction="row"
      >
        <Grid item xs={12} md={2} sx={{ height: '100%' }}>
          <DivFlex>
            <div>
              <Typography variant="h3">
                {comandasPorFase.reduce((acc, item: any) => acc + item.quantidade, 0)}
              </Typography>
            </div>
            <Typography variant="h4">
              Total de Comandas
            </Typography>
          </DivFlex>
        </Grid>

        <Grid item xs={12} md={2}>
          <DivFlex>
            <div>
              <Typography variant="h3">
                {comandasPorStatus.find((item: any) => item.status == 'Ativo')?.quantidade ?? 0}
              </Typography>
            </div>
            <Typography variant="h4">
              Ativos
            </Typography>
          </DivFlex>
        </Grid>

        <Grid item xs={12} md={2}>
          <DivFlex>
            <div>
              <Typography variant="h3">
                {comandasPorFase.find((item: any) => item.fase == 'Think & Plan')?.quantidade ?? 0}
              </Typography>
            </div>
            <Typography variant="h4">
              Think & Plan
            </Typography>
          </DivFlex>
        </Grid>

        <Grid item xs={12} md={2}>
          <DivFlex>
            <div>
              <Typography variant="h3">
                {comandasPorFase.find((item: any) => item.fase == 'GO TO MARKET')?.quantidade ?? 0}
              </Typography>
            </div>
            <Typography variant="h4">
              Go To Market
            </Typography>
          </DivFlex>
        </Grid>

        <Grid item xs={12} md={2}>
          <DivFlex>
            <div style={{ color: "blue" }}>
              <Typography variant="h3" style={{ color: "blue" }}>
                {comandasPorStatus.find((item: any) => item.status == 'Recolocado')?.quantidade ?? 0}
                =
                {getPorcentagemStatus('Recolocado')}
              </Typography>
            </div>
            <Typography variant="h4" style={{ color: "blue" }}>
              Recolocados
            </Typography>
          </DivFlex>
        </Grid>

        <Grid item xs={12} md={2}>

        </Grid>
      </GridItem>

      <Grid item xs={12} md={5.9}>
        <GridItem
          item
          sx={{ marginBottom: '10px', width: '100%', padding: '10px' }}
        >
          <BarChartComponent data={comandasPorFase.map(item => ({ label: item.fase, value: item.quantidade }))} title="Executivo por Fase" loading={loadingComandasPorFase} />
        </GridItem>

        <GridItem
          item
          sx={{ marginBottom: '10px', width: '100%', padding: '10px' }}
        >
          <BarChartComponent
            data={comandasPorStatus.map(item => ({ label: item.status, value: item.quantidade }))}
            title="Status do Projeto" loading={loadingComandasPorStatus}
          />
        </GridItem>
      </Grid>

      <Grid item xs={.2} />

      <GridItem item xs={12} md={5.9} style={{ maxHeight: '450px' }}>
        <div style={{ height: '400px', width: '100%' }}>
          <PieChartComponent
            data={comandasPorStatus.map(item => ({ label: item.status, value: item.quantidade }))}
            title="Status dos Programas" loading={loadingComandasPorStatus}
          />
        </div>
      </GridItem>

      <GridItem item xs={12} style={{ padding: '15px', height: '420px' }}>
        <Typography variant="h3">
          Etapas já realizadas
        </Typography>
        <DataGrid
          rows={contatos}
          columns={columns}
          sx={{ minHeight: '300px', maxHeight: '350px' }}
          loading={loadingEtapasJaRealizadas}
        />
      </GridItem>
    </ContainerPages >
  )
}

const GridItem = styled(Grid)`
  width: 100%;
  background-color: #f5f5f5;
  border-radius: 16px;
  border: 1px solid #aaa;
`;

const DivFlex = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  text-align: center;
  height: 100 %;
`;

export default Dashboard;
