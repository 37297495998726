import { useRecoilValue } from "recoil";
import { IBaseResponse } from "../../commons/types/base";
import { IComandasPorFase, IComandasPorStatus, IEtapasJaRealizadas } from "../../commons/types/dashboard";
import { IContato, IContatoDetalhes } from "../../commons/types/empresa/contatos";
import { useAxiosWrapper } from "../../services/axiosWrapper";
import { authAtom } from "../usuarios/AuthState";

function useContatosActions() {
  const axiosWrapper = useAxiosWrapper();
  const authState = useRecoilValue(authAtom);
  const baseUrl = `${process.env.REACT_APP_API_URL}/v1/empresas/${authState!.empresaId}/contatos`;

  return {
    getContatos,
    getContatoDetalhes,
  }

  async function getContatos(): Promise<IContato[]> {
    const resp: IContato[] = await axiosWrapper.get(`${baseUrl}`);
    return resp;
  }

  async function getContatoDetalhes(id: string): Promise<IContatoDetalhes> {
    const resp: IContatoDetalhes = await axiosWrapper.get(`${baseUrl}/${id}`);
    return resp;
  }
}

export { useContatosActions };

