import React from 'react';
import { Pie } from 'react-chartjs-2';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';

ChartJS.register(ArcElement, Tooltip, Legend);

const PieChartComponent: React.FC<any> = ({ data, title }) => {
  const getRandomColor = () => {
    let r = Math.floor(Math.random() * 256);
    let g = Math.floor(Math.random() * 256);
    let b = Math.floor(Math.random() * 256);
    return `rgba(${r}, ${g}, ${b}, 0.5)`;
  }

  const chartData = {
    labels: data.map((d: any) => d.label),
    datasets: [{
      data: data.map((d: any) => d.value),
      backgroundColor: data.map(() => getRandomColor()),
      hoverOffset: 4,
    }]
  };

  const options = {
  };

  return <Pie data={chartData} options={options} />
};

export default PieChartComponent;

